//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BlendedLearning',
  props: {
    eyebrow: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      required: true,
      validator(image) {
        return 'url' in image && 'alternativeText' in image;
      },
    },
    liveTitle: {
      type: String,
      required: true,
    },
    liveSubtitle: {
      type: String,
      required: true,
    },
    digitalTitle: {
      type: String,
      required: true,
    },
    digitalSubtitle: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: '',
    },
  },
};
