//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionHeading from '@/components/molecules/section-heading/SectionHeading.vue';

export default {
  name: 'QuoteBanner',
  components: {
    SectionHeading,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    logo: {
      type: Object,
      required: true,
      validator: (value) =>
        'alternativeText' in value && ('url' in value || 'sourceSet' in value),
    },
    backgroundImage: {
      type: Object,
      required: true,
      validator: (value) =>
        'alternativeText' in value && ('url' in value || 'sourceSet' in value),
    },
    quotation: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
  },
};
