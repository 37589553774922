import { render, staticRenderFns } from "./VideoBlock.vue?vue&type=template&id=616582e6&scoped=true&"
import script from "./VideoBlock.vue?vue&type=script&lang=js&"
export * from "./VideoBlock.vue?vue&type=script&lang=js&"
import style0 from "./VideoBlock.vue?vue&type=style&index=0&id=616582e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "616582e6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionIntro: require('/usr/src/web-www/components/molecules/section-intro/SectionIntro.vue').default,BaseImage: require('/usr/src/web-www/components/atoms/base-image/BaseImage.vue').default,BaseButton: require('/usr/src/web-www/components/atoms/base-button/BaseButton.vue').default,WistiaVideo: require('/usr/src/web-www/components/atoms/wistia-video/WistiaVideo.vue').default,BaseText: require('/usr/src/web-www/components/atoms/base-text/BaseText.vue').default})
