//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CalloutFact',
  props: {
    facts: {
      type: Array,
      default: () => [],
      required: true,
      validator: (facts) => facts.every((item) => 'text' in item),
    },
  },
  computed: {
    isMany() {
      return this.$props.facts.length > 1;
    },
  },
};
