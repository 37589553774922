import { render, staticRenderFns } from "./ContentCards.vue?vue&type=template&id=bc8e0aa6&scoped=true&"
import script from "./ContentCards.vue?vue&type=script&lang=js&"
export * from "./ContentCards.vue?vue&type=script&lang=js&"
import style0 from "./ContentCards.vue?vue&type=style&index=0&id=bc8e0aa6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc8e0aa6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionIntro: require('/usr/src/web-www/components/molecules/section-intro/SectionIntro.vue').default,ContentCard: require('/usr/src/web-www/components/molecules/content-card/ContentCard.vue').default})
