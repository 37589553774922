//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SolutionsListHighlight',
  props: {
    eyebrow: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    solutionsList: {
      type: Array,
      required: true,
      validator(solutionsList) {
        return solutionsList.every(
          (item) =>
            'title' in item.solution &&
            'subtitle' in item.solution &&
            'slug' in item.solution &&
            'thumbnailImage' in item.solution,
        );
      },
    },
    button: {
      type: Object,
      default: () => {},
    },
  },
};
