//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import global from '@/mixins/global.js';
import TwoToneTitle from '@/components/molecules/two-tone-title/TwoToneTitle.vue';
import BaseButton from '@/components/atoms/base-button/BaseButton.vue';

export default {
  name: 'Hero',
  components: {
    TwoToneTitle,
    BaseButton,
  },
  mixins: [global],
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    heroParagraph: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default: () => [],
      validator: (buttons) =>
        buttons.every((button) => 'label' in button && 'path' in button),
    },
    primaryButtonClickTrackEvent: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      required: true,
      validator: (image) =>
        'alternativeText' in image && ('url' in image || 'sourceSet' in image),
    },
  },
  data() {
    return {
      gtmEventPending: false,
    };
  },
  computed: {
    ...mapState({
      pageTopOffset: (state) => state.global.pageTopOffset,
    }),
  },
  methods: {
    async handlePrimaryButtonClick(e, button) {
      if (
        !this.gtmEventPending &&
        this.$props.primaryButtonClickTrackEvent &&
        !button.secondary
      ) {
        if (e) e.preventDefault();
        this.gtmEventPending = true;

        const eventObject = {
          page_path: this.$route.path,
          event: this.$props.primaryButtonClickTrackEvent,
        };

        await this.pushGTMEvent(eventObject);
        this.gtmEventPending = false;

        // proceed with navigation
        if (button.external) {
          const linkUrl = e?.target?.href;
          if (linkUrl) window.location = linkUrl;
        } else {
          this.$router.push({
            path: button.path,
          });
        }
      }
    },
  },
};
