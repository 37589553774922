//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ValueProp',
  props: {
    title: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      required: true,
      validator(image) {
        return 'url' in image && 'alternativeText' in image;
      },
    },
    firstTitle: {
      type: String,
      required: true,
    },
    firstSubtitle: {
      type: String,
      required: true,
    },
    secondTitle: {
      type: String,
      required: true,
    },
    secondSubtitle: {
      type: String,
      required: true,
    },
    link: {
      default: null,
      type: String,
      required: false,
    },
  },
};
