//
//
//
//

import global from '@/mixins/global.js';

export default {
  name: 'WistiaVideo',
  mixins: [global],
  props: {
    url: {
      default: '',
      type: String,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: 'wistia_video_embed_container',
    },
  },
  data() {
    return {
      settings: {
        accountKey: 'wistia-production_79919',
        container: this.$props.id,
        doNotTrack: false,
        uuid: 'wistia_video_embed',
        dontFit: 'false',
        videoFoam: 'true',
        autoPlay: this.$props.autoplay,
        playerColor: '#00E200',
      },
      playerInstance: null,
      percentWatched: null,
      videoName: null,
      pagePath: this.$route.path,
    };
  },
  computed: {
    wistiaId() {
      const start = this.$props.url.lastIndexOf('/') + 1;
      const finish = this.$props.url.lastIndexOf('?');
      return this.$props.url.slice(start, finish);
    },
  },
  async beforeDestroy() {
    if (!this.$isServer && this.playerInstance.state() === 'playing')
      await this.triggerGaVideoProgressEvt();
  },
  async mounted() {
    await this.loadWistia();
  },
  methods: {
    async loadWistia() {
      if (!this.$isServer) {
        const WistiaPlayerApiLoader = await import('wistia-player-api-loader');

        WistiaPlayerApiLoader.load(this.load);
      }
    },
    load(Wistia) {
      const wistiaEmbed = Wistia.embed(this.wistiaId, this.settings);

      wistiaEmbed.bind('end', this.finish);
      wistiaEmbed.bind('play', this.onPlay);
      wistiaEmbed.bind('pause', this.onPause);
      this.playerInstance = wistiaEmbed;
    },
    finish() {
      this.$emit('finish');
      this.percentWatched = this.playerInstance.percentWatched();
      if (!this.$isServer) this.triggerGaVideoProgressEvt();
    },
    onPlay() {
      this.videoName = this.playerInstance.name();
    },
    onPause() {
      this.percentWatched = this.playerInstance.percentWatched();
      if (!this.$isServer) this.triggerGaVideoProgressEvt();
    },
    async triggerGaVideoProgressEvt() {
      if (typeof this.percentWatched !== 'number') return;

      const fixedPercentWatched = parseFloat(this.percentWatched).toFixed(2);
      const eventObj = {
        event: 'video_progress',
        video_name: `${this.videoName} (${this.wistiaId})`,
        video_progress: fixedPercentWatched,
        page_path: this.pagePath,
      };

      await this.pushGTMEvent(eventObj);
    },
  },
};
