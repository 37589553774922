//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VideoBlock',
  props: {
    image: {
      type: [Object, null],
      default: () => null,
      validator: (image) =>
        'alternativeText' in image && ('url' in image || 'sourceSet' in image),
    },
    video: {
      type: String,
      default: '',
    },
    featured: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'wistia_video_embed_container',
    },
    annotation: {
      type: String,
      default: '',
    },
    sectionIntro: {
      type: Object,
      default: () => ({
        sectionEyebrow: '',
        sectionTitle: '',
        sectionParagraph: '',
      }),
    },
  },
  data: () => ({
    showVideo: false,
    autoplay: true,
  }),
  created() {
    this.autoplay = !!this.$props.image;
    this.showVideo = !this.$props.image;
  },
  methods: {
    playVideo() {
      this.showVideo = true;
    },
    finishVideo() {
      if (this.$props.image) {
        this.showVideo = false;
      }
    },
  },
};
