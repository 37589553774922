import { render, staticRenderFns } from "./InfoIcon.vue?vue&type=template&id=49ef2324&scoped=true&"
import script from "./InfoIcon.vue?vue&type=script&lang=js&"
export * from "./InfoIcon.vue?vue&type=script&lang=js&"
import style0 from "./InfoIcon.vue?vue&type=style&index=0&id=49ef2324&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ef2324",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/usr/src/web-www/components/atoms/base-icon/BaseIcon.vue').default,BaseText: require('/usr/src/web-www/components/atoms/base-text/BaseText.vue').default})
