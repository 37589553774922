//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FeaturedContent',
  props: {
    featuredContenTag: {
      type: String,
      default: '',
    },
    featuredContentTitle: {
      type: String,
      required: true,
    },
    featuredContentText: {
      type: String,
      required: true,
    },
    featuredContentLink: {
      type: String,
      default: '',
    },
    featuredContentImage: {
      type: Object,
      required: true,
      validator: (image) =>
        'alternativeText' in image && ('url' in image || 'sourceSet' in image),
    },
    featuredContentButton: {
      type: String,
      default: '',
    },
    featuredContentImageLeftAligned: {
      type: Boolean,
      default: true,
    },
  },
};
