//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DynamicTable',
  props: {
    rows: {
      type: Array,
      required: true,
    },
    turnFirstRowToTableHeader: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    cTableHeader() {
      return this.$props.turnFirstRowToTableHeader
        ? this.$props.rows[0].cell
        : {};
    },
    cTableRows() {
      return this.$props.turnFirstRowToTableHeader
        ? this.$props.rows.slice(1)
        : this.$props.rows;
    },
  },
};
