//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLink from '@/components/atoms/base-link/BaseLink.vue';
import TwoToneTitle from '@/components/molecules/two-tone-title/TwoToneTitle.vue';
import BaseImage from '@/components/atoms/base-image/BaseImage.vue';

export default {
  name: 'SolutionCardMinor',
  components: {
    BaseLink,
    TwoToneTitle,
    BaseImage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      required: true,
    },
    thumbnailImage: {
      type: Object,
      required: true,
      validator: (thumbnailImage) =>
        'alternativeText' in thumbnailImage &&
        ('url' in thumbnailImage || 'sourceSet' in thumbnailImage),
    },
  },
};
