import { render, staticRenderFns } from "./QuoteBanner.vue?vue&type=template&id=a4125552&scoped=true&"
import script from "./QuoteBanner.vue?vue&type=script&lang=js&"
export * from "./QuoteBanner.vue?vue&type=script&lang=js&"
import style0 from "./QuoteBanner.vue?vue&type=style&index=0&id=a4125552&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4125552",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionHeading: require('/usr/src/web-www/components/molecules/section-heading/SectionHeading.vue').default,BaseImage: require('/usr/src/web-www/components/atoms/base-image/BaseImage.vue').default,Container: require('/usr/src/web-www/components/atoms/container/Container.vue').default})
