//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InfoIcons',
  props: {
    sectionIntro: {
      type: Object,
      default: () => ({
        sectionEyebrow: '',
        sectionTitle: '',
        sectionParagraph: '',
      }),
    },
    infoIcon: {
      type: Array,
      default: () => [],
    },
  },
};
