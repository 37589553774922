import { render, staticRenderFns } from "./ValueProp.vue?vue&type=template&id=1df85b1e&scoped=true&"
import script from "./ValueProp.vue?vue&type=script&lang=js&"
export * from "./ValueProp.vue?vue&type=script&lang=js&"
import style0 from "./ValueProp.vue?vue&type=style&index=0&id=1df85b1e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1df85b1e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionHeading: require('/usr/src/web-www/components/molecules/section-heading/SectionHeading.vue').default,TwoToneTitle: require('/usr/src/web-www/components/molecules/two-tone-title/TwoToneTitle.vue').default,BaseImage: require('/usr/src/web-www/components/atoms/base-image/BaseImage.vue').default,Container: require('/usr/src/web-www/components/atoms/container/Container.vue').default})
