//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Hero from '@/components/organisms/hero/hero';
import HeroBanner from '@/components/organisms/hero-banner/HeroBanner';
import SectionIntro from '@/components/molecules/section-intro/SectionIntro';
import RelatedContent from '@/components/organisms/related-content/RelatedContent';
import CalloutFact from '@/components/molecules/callout-fact/CalloutFact.vue';
import HeroBannerSolutions from '@/components/organisms/hero-banner/HeroBannerSolutions';
import VideoBlock from '@/components/organisms/video-block/VideoBlock';
import InfoIcons from './organisms/info-icons/InfoIcons';
import FeaturedContent from './organisms/featured-content/FeaturedContent';
import TextBlock from './molecules/text-block/TextBlock';
import ImageBlock from './molecules/image-block/ImageBlock';
import ContentCards from './organisms/content-cards/ContentCards';
import PromoPopup from './molecules/promo-popup/PromoPopup';
import ClientLogos from './organisms/client-logos/ClientLogos';
import ValueProp from './organisms/value-prop/ValueProp';
import BlendedLearning from './organisms/bleanded-learning/BlendedLearning';
import QuoteBanner from './organisms/quote-banner/QuoteBanner';
import AwardsBanner from './organisms/awards-banner/AwardsBanner';
import SolutionsListHighlight from './organisms/solutions-list-highlight/SolutionsListHighlight';
import DynamicTable from './molecules/dynamic-table/DynamicTable';
import BaseList from './atoms/base-list/BaseList';
import CtaModule from './organisms/cta-module/CtaModule';

export default {
  name: 'RenderComponent',
  components: {
    Hero,
    HeroBanner,
    SectionIntro,
    RelatedContent,
    CalloutFact,
    InfoIcons,
    FeaturedContent,
    TextBlock,
    ImageBlock,
    ContentCards,
    PromoPopup,
    ClientLogos,
    ValueProp,
    BlendedLearning,
    QuoteBanner,
    AwardsBanner,
    SolutionsListHighlight,
    DynamicTable,
    HeroBannerSolutions,
    BaseList,
    CtaModule,
    VideoBlock,
  },
  props: {
    component: {
      type: Object,
      default: () => {},
    },
  },
};
