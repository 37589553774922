//
//
//
//
//
//

export default {
  name: 'TextBlock',
  props: {
    richText: {
      type: String,
      required: true,
    },
  },
};
