import { render, staticRenderFns } from "./InfoIcons.vue?vue&type=template&id=7713d3d5&scoped=true&"
import script from "./InfoIcons.vue?vue&type=script&lang=js&"
export * from "./InfoIcons.vue?vue&type=script&lang=js&"
import style0 from "./InfoIcons.vue?vue&type=style&index=0&id=7713d3d5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7713d3d5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionIntro: require('/usr/src/web-www/components/molecules/section-intro/SectionIntro.vue').default,InfoIcon: require('/usr/src/web-www/components/molecules/info-icon/InfoIcon.vue').default})
