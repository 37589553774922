//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'PromoPopup',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: Object,
      default: () => ({ variant: '', text: '' }),
      validator: (val) => 'variant' in val && 'text' in val,
    },
    link: {
      type: String,
      required: true,
    },
    externalLink: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    display: false,
  }),
  computed: {
    ...mapState({
      pageTopOffset: (state) => state.global.pageTopOffset,
    }),
  },
  mounted() {
    if (this.show) {
      setTimeout(() => (this.display = true), 2000);
    }
  },
};
