import { render, staticRenderFns } from "./SolutionCardMinor.vue?vue&type=template&id=1979a9d2&"
import script from "./SolutionCardMinor.vue?vue&type=script&lang=js&"
export * from "./SolutionCardMinor.vue?vue&type=script&lang=js&"
import style0 from "./SolutionCardMinor.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TwoToneTitle: require('/usr/src/web-www/components/molecules/two-tone-title/TwoToneTitle.vue').default,BaseImage: require('/usr/src/web-www/components/atoms/base-image/BaseImage.vue').default,BaseLink: require('/usr/src/web-www/components/atoms/base-link/BaseLink.vue').default})
