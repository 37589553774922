var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.component.__typename === 'ComponentHeroHero')?_c('div',[_c('Hero',{attrs:{"title":_vm.component.Title,"subtitle":_vm.component.Subtitle,"buttons":_vm.component.HeroButtons,"hero-paragraph":_vm.component.HeroParagraph,"image":_vm.component.BackgroundImage,"primary-button-click-track-event":_vm.component.primaryButtonClickTrackEvent}})],1):(_vm.component.__typename === 'ComponentHeroHeroBanner')?_c('HeroBanner',_vm._b({},'HeroBanner',_vm.component,false)):(_vm.component.__typename === 'ComponentHeroLandingHero')?_c('div',{staticClass:"verticalMargins"},[_c('HeroBannerSolutions',{attrs:{"title":_vm.component.title,"subtitle":_vm.component.subtitle,"paragraph":_vm.component.heroParagraph,"image":_vm.component.heroImage,"buttons":_vm.component.heroButtons}})],1):(_vm.component.__typename === 'ComponentBaseSectionIntro')?_c('SectionIntro',_vm._b({},'SectionIntro',_vm.component,false)):(_vm.component.__typename === 'ComponentInterlinksRelatedLinks')?_c('div',{staticClass:"container verticalMargins"},[_c('RelatedContent',{attrs:{"content":[
        _vm.component.Intro ].concat( _vm.component.ArticleLinks,
        _vm.component.WebinarLinks,
        _vm.component.WhitepaperLinks.map(function (link) { return (Object.assign({}, link,
          {sourceSet: []})); }),
        _vm.component.CasestudyLinks,
        _vm.component.DynamicPageLinks,
        _vm.component.SolutionLinks,
        _vm.component.TopicLinks ).filter(function (_) { return _; })}})],1):(_vm.component.__typename === 'ComponentBaseSingleCalloutFact')?_c('div',{staticClass:"container verticalMargins"},[_c('CalloutFact',{attrs:{"facts":[_vm.component]}})],1):(_vm.component.__typename === 'ComponentModulesInfoIcons')?_c('div',{staticClass:"container verticalMargins"},[_c('InfoIcons',_vm._b({},'InfoIcons',_vm.component,false))],1):(_vm.component.__typename === 'ComponentModulesFeaturedContent')?_c('div',{staticClass:"container verticalMargins"},[_c('FeaturedContent',_vm._b({},'FeaturedContent',_vm.component,false))],1):(_vm.component.__typename === 'ComponentEditorialTextBlock')?_c('div',{staticClass:"container verticalMargins richtext-component"},[_c('TextBlock',_vm._b({},'TextBlock',_vm.component,false))],1):(_vm.component.__typename === 'ComponentEditorialImageBlock')?_c('div',{staticClass:"container verticalMargins"},[_c('ImageBlock',_vm._b({},'ImageBlock',_vm.component,false))],1):(_vm.component.__typename === 'ComponentModulesForm')?_c('FormComponent',{attrs:{"form-url":_vm.component.FormURL,"hubspot-form-id":_vm.component.hubspotFormId,"background-image":_vm.component.BackgroundImage,"notification-message":_vm.component.notification_message}}):(_vm.component.__typename === 'ComponentModulesContentCards')?_c('div',{class:[
    ("component-section " + (_vm.component.backgroundColour.BackgroundColour
        ? ("component-section__bg-" + (_vm.component.backgroundColour.BackgroundColour))
        : '')) ]},[_c('div',{staticClass:"container"},[_c('ContentCards',_vm._b({},'ContentCards',_vm.component,false))],1)]):(_vm.component.__typename === 'ComponentEditorialPromoPopup')?_c('PromoPopup',_vm._b({attrs:{"title":_vm.component.Title,"tag":{ text: _vm.component.tag, variant: 'dark' }}},'PromoPopup',_vm.component,false)):(_vm.component.__typename === 'ComponentModulesClientLogos')?_c('ClientLogos',_vm._b({attrs:{"title":_vm.component.Title}},'ClientLogos',_vm.component,false)):(_vm.component.__typename === 'ComponentModulesValuePropModule')?_c('div',[_c('ValueProp',_vm._b({attrs:{"title":_vm.component.Title}},'ValueProp',_vm.component,false))],1):(_vm.component.__typename === 'ComponentModulesBlendedLearningModule')?_c('div',{staticClass:"verticalMargins"},[_c('BlendedLearning',_vm._b({},'BlendedLearning',_vm.component,false))],1):(_vm.component.__typename === 'ComponentEditorialQuotes')?_c('QuoteBanner',_vm._b({attrs:{"title":_vm.component.Title}},'QuoteBanner',_vm.component.quotes[0],false)):(_vm.component.__typename === 'ComponentEditorialAwardsBanner')?_c('div',{staticClass:"container verticalMargins"},[_c('AwardsBanner',{attrs:{"title":_vm.component.Text,"awards":_vm.component.AwardImage.map(function (x) { return ({ name: '', image: x.url }); })}})],1):(
    _vm.component.__typename === 'ComponentEditorialSolutionsListHighlight'
  )?_c('div',{staticClass:"container verticalMargins"},[_c('SolutionsListHighlight',{attrs:{"title":_vm.component.Title,"eyebrow":_vm.component.Eyebrow,"solutions-list":_vm.component.solutions.map(function (solution) { return ({ solution: solution }); }),"button":_vm.component.Button}})],1):(_vm.component.__typename === 'ComponentEditorialDynamicTable')?_c('div',{staticClass:"container verticalMargins"},[_c('DynamicTable',{attrs:{"rows":_vm.component.rows,"turn-first-row-to-table-header":_vm.component.turnFirstRowToTableHeader}})],1):(_vm.component.__typename === 'ComponentModulesIconsList')?_c('div',{staticClass:"container verticalMargins richtext-component"},[_c('BaseList',{staticClass:"list-module",attrs:{"tag":"ul","list":_vm.component.BulletItem}})],1):(_vm.component.__typename === 'ComponentModulesCtaModule')?_c('CtaModule',{attrs:{"title":_vm.component.ctaModuleTitle,"subtitle":_vm.component.ctaModuleSubtitle,"primary-button":_vm.component.primaryButton,"secondary-button":_vm.component.secondaryButton,"image":_vm.component.image,"has-default-image":false,"primary-button-click-track-event":_vm.component.primaryButtonClickTrackEvent}}):(_vm.component.__typename === 'ComponentEditorialVideoBlock')?_c('section',{class:[
    ("component-section " + (_vm.component.backgroundColour.BackgroundColour
        ? ("component-section__bg-" + (_vm.component.backgroundColour.BackgroundColour))
        : '')) ]},[_c('div',{staticClass:"container"},[_c('VideoBlock',_vm._b({},'VideoBlock',_vm.component,false))],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }