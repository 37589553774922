//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ImageBlock',
  props: {
    image: {
      type: Object,
      required: true,
      validator: (image) =>
        'alternativeText' in image && ('url' in image || 'sourceSet' in image),
    },
    caption: {
      type: String,
      default: '',
    },
  },
};
