//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '@/mixins/global.js';

export default {
  name: 'CtaModule',
  mixins: [global],
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    paragraph: {
      type: String,
      default: '',
    },
    primaryButton: {
      type: Object,
      default: () => {},
      validator(primaryButton) {
        return 'path' in primaryButton && 'label' in primaryButton;
      },
    },
    primaryButtonClickTrackEvent: {
      type: String,
      default: '',
    },
    secondaryButton: {
      type: Object,
      default: () => {},
      validator(secondaryButton) {
        return 'path' in secondaryButton && 'label' in secondaryButton;
      },
    },
    image: {
      type: Object,
      default: () => {},
      validator: (value) =>
        'alternativeText' in value && ('url' in value || 'sourceSet' in value),
    },
    hasDefaultImage: {
      type: Boolean,
      default: true,
    },
    rightAlign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gtmEventPending: false,
      defaultImage: {
        url: '/images/template/1440/1x/cta.jpg',
        alternativeText: 'CTA banner',
        sourceSet: [
          {
            url: '/images/template/1920/1x/cta.jpg 1x, /images/template/1920/2x/cta.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 1920,
          },
          {
            url: '/images/template/1440/1x/cta.jpg 1x, /images/template/1440/2x/cta.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 1440,
          },
          {
            url: '/images/template/1280/1x/cta.jpg 1x, /images/template/1280/2x/cta.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 1280,
          },
          {
            url: '/images/template/1024/1x/cta.jpg 1x, /images/template/1024/2x/cta.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 1024,
          },
          {
            url: '/images/template/960/1x/cta.jpg 1x, /images/template/960/2x/cta.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 960,
          },
          {
            url: '/images/template/768/1x/cta.jpg 1x, /images/template/768/2x/cta.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 768,
          },
          {
            url: '/images/template/480/1x/cta.jpg 1x, /images/template/480/2x/cta.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 480,
          },
          {
            url: '/images/template/360/1x/cta.jpg 1x, /images/template/360/2x/cta.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 0,
          },
        ],
      },
    };
  },

  methods: {
    async handlePrimaryButtonClick(e) {
      if (!this.gtmEventPending && this.$props.primaryButtonClickTrackEvent) {
        if (e) e.preventDefault();
        this.gtmEventPending = true;

        const eventObject = {
          page_path: this.$route.path,
          event: this.$props.primaryButtonClickTrackEvent,
        };

        await this.pushGTMEvent(eventObject);
        this.gtmEventPending = false;

        const linkUrl = e?.target?.href;
        if (linkUrl) window.location = linkUrl;

        // proceed with navigation
        if (this.$props.primaryButton.external) {
          const linkUrl = e?.target?.href;
          if (linkUrl) window.location = linkUrl;
        } else {
          this.$router.push({
            path: this.$props.primaryButton.path,
          });
        }
      }
    },
  },
};
