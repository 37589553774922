//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseHeading from '@/components/atoms/base-heading/BaseHeading.vue';

export default {
  name: 'SectionHeading',
  components: {
    BaseHeading,
  },
  props: {
    eyebrow: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
