//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseHeading from '@/components/atoms/base-heading/BaseHeading.vue';
import BaseImage from '@/components/atoms/base-image/BaseImage.vue';

export default {
  name: 'AwardsBanner',
  components: {
    BaseHeading,
    BaseImage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    awards: {
      type: Array,
      required: true,
      validator(awards) {
        return awards.every((award) => 'name' in award && 'image' in award);
      },
    },
  },
};
