//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseHeading from '@/components/atoms/base-heading/BaseHeading.vue';

export default {
  name: 'ClientLogos',
  components: {
    BaseHeading,
  },
  props: {
    title: {
      type: String,
      default: 'We have helped over half the FTSE 100 and the S&P 100',
    },
    logos: {
      type: Array,
      default: () => [],
      validator(logos) {
        return logos.every(
          (logo) => 'companyName' in logo && 'logoImage' in logo,
        );
      },
    },
    variant: {
      type: String,
      default: 'light',
    },
  },
  computed: {
    cLogos() {
      if (this.$props.logos && this.$props.logos.length >= 1) {
        return this.$props.logos;
      } else
        return [
          {
            companyName: 'audi',
            logoImage: {
              url: '/images/client-logos/audi.svg',
              alternativeText: 'audi',
            },
          },
          {
            companyName: 'microsoft',
            logoImage: {
              url: '/images/client-logos/microsoft.svg',
              alternativeText: 'microsoft',
            },
          },
          {
            companyName: 'gsk',
            logoImage: {
              url: '/images/client-logos/gsk.svg',
              alternativeText: 'gsk',
            },
          },
          {
            companyName: 'met-lif',
            logoImage: {
              url: '/images/client-logos/met-life.svg',
              alternativeText: 'met-lif',
            },
          },
          {
            companyName: 'southwest',
            logoImage: {
              url: '/images/client-logos/southwest.svg',
              alternativeText: 'southwest',
            },
          },
          {
            companyName: 'unilever',
            logoImage: {
              url: '/images/client-logos/unilever.svg',
              alternativeText: 'unilever',
            },
          },
          {
            companyName: 'santander',
            logoImage: {
              url: '/images/client-logos/santander.svg',
              alternativeText: 'santander',
            },
          },
          {
            companyName: 'vodafone',
            logoImage: {
              url: '/images/client-logos/vodafone.svg',
              alternativeText: 'vodafone',
            },
          },
        ];
    },
  },
};
